<template>
  <div class="good_info">
    <!-- 顶部导航 -->
    <van-nav-bar class="topnav" title="商品详情" left-arrow @click-left="toBack" :border="false" />
    <!-- 轮播图 -->
    <div class="lunbo">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in goodsDetail.album" :key="index">
          <img :src="adddom(item)" alt />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 商品积分/商品名称 -->
    <div class="good">
      <div class="jifen">
        <span>{{goodsDetail.spec.score}}</span>积分
      </div>
      <div class="title">
        {{goodsDetail.title}}
        <span>{{goodsDetail.spec.goods_spec_name}}</span>
      </div>
    </div>
    <!-- 兑换说明 -->
    <div class="dui">
      <div class="title">兑换说明</div>
      <div v-html="goodsDetail.content"></div>
    </div>
    <!-- 立即兑换 -->
    <div class="ljbtn" @click="todui">立即兑换</div>
    <!-- 兑换弹出 -->
    <van-popup class="duipop" v-model:show="showDui" closeable close-icon="close">
      <div class="title">提示</div>
      <div class="isdui">是否兑换该商品</div>
      <!-- <div class="btn" @click="dui">确定</div> -->
      <van-button style="display:block;" :loading="submitLoading" round plain class="btn" type="info" @click="dui">确定</van-button>
    </van-popup>
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      submitLoading: false, // 按钮加载状态
      loadShow: false,
      banner: [
        require("../../../public/statics/images/index/banner_2.png"),
        require("../../../public/statics/images/index/1.jpg")
      ],
      showDui: false,
      goodsId: "", //商品id
      specId: "", //规格id
      goodsDetail: {
        album: [],
        spec: [
          {
            goods_spec_name: "",
            score: 0
          }
        ]
      }
    };
  },
  created() {
    this.loadShow = true;
    this.goodsId = this.$route.query.id;
    this.getDetails(this.goodsId);
  },
  methods: {
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    getDetails(id) {
      var that = this;
      var params = {
        token: Cookies.get("spToken"),
        id: id
      };
      that.$api.integral.detail(params).then(res => {
        that.loadShow = false;
        if (res.code == 200) {
          that.goodsDetail = res.result;
          that.specId = that.goodsDetail.spec.id;
        }
      });
    },
    toBack() {
      this.$router.go(-1);
    },
    // 点击立即兑换
    todui() {
      this.showDui = true;
    },
    // 点击弹框确定下单
    dui() {
      var that = this;
      that.submitLoading = true
      var params = {
        token: Cookies.get("spToken"),
        goods_id: that.goodsId,
        goods_spec_id: that.specId
      };
      that.$api.integral.create(params).then(res => {
        that.submitLoading = false
        if (res.code == 200) {
          that.showDui = false;
          if (res.result.goods_type == 2) {
            that.$router.push({
              path: "/dui_record",
              query: {
              }
            });
          } else {
            that.$router.push({
              path: "/address",
              query: {
                orderid: res.result.order_id
              }
            });
          }
        }
      });
    }
  }
};
</script>

<style>
.good_info .duipop .btn{
  border: none;
}
</style>